import { template as template_a4e1c65cd245451eb85e2177a6f7f89c } from "@ember/template-compiler";
const SidebarSectionMessage = template_a4e1c65cd245451eb85e2177a6f7f89c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
